import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CardContent,
  Link,
  Grid,
  ImageList,
  TextField,
} from '@mui/material';
import { Icon } from '@iconify/react';
import Scrollbar from '../../components/Scrollbar';
import { getReq, patchReq } from '../../data/ApiReq';
import ApiUrl from '../../data/ApiUrl';
import noImg from '../../assets/no-img.jpg';
import Iconify from '../../components/Iconify';

export default function Products() {
  const [currentPage, setCurrentPage] = useState();
  const [totalPosts, setTotalPosts] = useState(206);
  const [postsPerPage, setPostsPerPage] = useState(20);

  const initialState = {
    page: 1,
    limit: 20,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };

  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
  };

  const [productData, setProductData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [state, setState] = useState(initialState);

  const [brands, setBrands] = useState([]);
  const [quikEdit, setQuikEdit] = useState({
    id: '',
  });
  const [apiResult, setApiResult] = useState(initialResult);
  const [filterState, setFilterState] = useState({});

  const getCategories = async () => {
    const response = await getReq({ url: 'inventory/api/category' });
    if (!response.error) {
      setCategories(response.data);
      return 1;
    }
    return 0;
  };

  const getBrands = async () => {
    const response = await getReq({ url: 'inventory/api/brand' });
    if (!response.error) {
      setBrands(response.data);
      return 1;
    }
    return 0;
  };

  const Paginations = () => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i += 1) {
      // console.log(state.totalCount)
      // console.log(state.limit)
      if (pageNumbers.push(i)) {
        pageNumbers.push(
          <li className="page-item " key={i}>
            <a
              className={i === state.page ? 'page-link bg-primary' : 'page-link'}
              href="#"
              onClick={() => getData(i, state.limit, filterState.searchText, filterState.query)}
            >
              {i}
            </a>
          </li>
        );
      }
    }
    return <ul className="pagination flex justify-content-end">{pageNumbers}</ul>;
  };

  const setEditItem = (id, name) => {
    if (id) {
      setState({
        ...state,
        qEdit: true,
      });
      setQuikEdit({
        ...quikEdit,
        id,
        name,
      });
    } else {
      setState({
        ...state,
        qEdit: false,
      });
      setQuikEdit({
        ...quikEdit,
        id: '',
      });
    }
  };

  const clearAll = () => {
    setFilterState({});
    setState({ ...initialState });
    getData();
  };

  // const getData = async () => {
  //   const res = await getReq({ url: 'product/getall' });
  //   if (!res.error) {
  //     setProductData(res.data);
  //     console.log(res.data);
  //   }
  // };

  const getData = async (pg, lm, isSearch, filter) => {
    const p = pg || state.page;
    const l = lm || state.limit;

    let query = `page=${p}&limit=${l}`;
    if (isSearch) {
      query = `page=${p}&limit=${l}&searchText=${isSearch}`;
      if (filter) {
        query += `&${filter}`;
      }
      setFilterState({ ...filterState, searchText: isSearch });
    }
    if (filter) {
      query = `page=${p}&limit=${l}&${filter}`;
      if (filterState.searchText) {
        query += `&searchText=${filterState.searchText}`;
      }
    }
    const response = await getReq({ url: `inventory/api/product?${query}` });
    if (!response.error) {
      console.log(response);
      setProductData(response.data);
      // console.log(state.totalCount);
      setState({
        ...state,
        page: response.pagination.page,
        limit: response.pagination.limit,
        totalCount: response.pagination.totalCount,
        totalPage: response.pagination.totalPages,
        hasNextPage: response.pagination.hasNextPage,
        qEdit: false,
      });
    }
  };
  useEffect(() => {
    getData();
    getCategories();
    getBrands();
  }, []);

  const handleFilterChange = (e) => {
    let query = `${e.target.name}=${e.target.value}`;
    if (filterState.catId && e.target.name !== 'catId') {
      query += `&catId=${filterState.catId}`;
    }
    if (filterState.brandId && e.target.name !== 'brandId') {
      query += `&brandId=${filterState.brandId}`;
    }
    if (filterState.sort && e.target.name !== 'sort') {
      query += `&sort=${filterState.sort}`;
    }

    setFilterState({
      ...filterState,
      [e.target.name]: e.target.value,
      query,
    });
    getData(1, state.limit, false, query);
  };

  const updateProduct = async (productIndex) => {
    setState({
      ...state,
      loading: true,
    });
    const data = {
      productId: quikEdit.id,
    };
    if (quikEdit.brandId) {
      data.brandId = quikEdit.brandId;
    }
    if (quikEdit.categoryId) {
      data.categoryId = quikEdit.categoryId;
    }
    if (quikEdit.oldPrice) {
      data.oldPrice = quikEdit.oldPrice;
    }
    if (quikEdit.sellingPrice) {
      data.sellingPrice = quikEdit.sellingPrice;
    }
    if (quikEdit.qty) {
      data.qty = quikEdit.qty;
    }
    data.name = quikEdit.name;
    const response = await patchReq({
      url: 'inventory/api/product/update',
      data,
    });

    // console.log(response);
    if (!response.error) {
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });

      toast.success('Updated Successfully');
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.data.message);
    }

    setEditItem(false);
    getData(state.page, state.limit, filterState.searchText, filterState.query);
    // getData();
  };

  useEffect(() => {
    getData();
    getCategories();
    getBrands();
  }, []);

  const productList = productData;

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" padding={5}>
        <Typography>
          <h2>Product List</h2>
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="/dashboard/inventory/addproduct"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add Product
        </Button>
      </Stack>

      {/* <Stack direction="row" justifyContent="space-between" >
      <TextField sx={{ mb: 3, mt: 3, width: "22.5%" }} id="outlined-basic" label="Search" size="small" variant="outlined" onChange={(e) =>
                          // setState({ ...state, searchText: e.target.value })
                          getData(1, state.limit, e.target.value)
                        } /> 
      <Paginations postsPerPage={postsPerPage} totalPosts={totalPosts} paginate={paginate}/>
      </Stack> */}

      <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mb: 3 }}>
        <Stack>
          {/* <Typography>
            <h4>Categories</h4>
          </Typography> */}
          <Stack direction="row">
            <Box sx={{ minWidth: 300 }}>
              <FormControl size="small" sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-simple-select-label">Categories</InputLabel>

                <Select
                  label="Categories"
                  name="catId"
                  onChange={handleFilterChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {categories.map((category, key) => (
                    <MenuItem key={key} value={category._id}>
                      {category.categoryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </Stack>

        <Stack sx={{ ml: 5 }}>
          {/* <Typography>
            <h4>Brands</h4>
          </Typography> */}
          <Stack direction="row">
            <Box sx={{ minWidth: 300 }}>
              <FormControl size="small" sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-simple-select-label">Brands</InputLabel>
                <Select
                  label="Brands"
                  name="brandId"
                  onChange={handleFilterChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {brands.map((category, key) => (
                    <MenuItem value={category._id} key={key}>
                      {category.brandName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </Stack>

        <Stack sx={{ ml: 5 }}>
          {/* <Typography>
            <h4>Sort By</h4>
          </Typography> */}
          <Stack direction="row">
            <Box sx={{ minWidth: 300 }}>
              <FormControl size="small" sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                <Select
                  label="Sort By"
                  name="sort"
                  onChange={handleFilterChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="name">Name Ascending</MenuItem>
                  <MenuItem value="-name">Name Descending</MenuItem>
                  <MenuItem value="sellingPrice">Price Low to High</MenuItem>
                  <MenuItem value="-sellingPrice">Price High to Low</MenuItem>
                  <MenuItem value="qty">Quantity Low to High</MenuItem>
                  <MenuItem value="-qty">Quantity High to Low</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </Stack>

        <Stack sx={{ ml: 35 }} display="flex" justifyContent="center">
          <Button onClick={clearAll} variant="outlined">
            Clear
          </Button>
        </Stack>
      </Stack>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sl.</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Price(AED)</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {productList.map((item, key) => (
                  <TableRow hover key={key}>
                    <TableCell>{state.limit * state.page + key + 1 - state.limit}</TableCell>
                    <TableCell>
                      {item.image[0] ? (
                        <Link to={`productdetails/${item._id}`}>
                          <img
                            height={100}
                            src={`${ApiUrl.img_url}/products/${item.image[0].smUrl}`}
                            alt="product"
                            className="product-list-img"
                          />
                        </Link>
                      ) : (
                        <ImageList src={noImg} alt="no image" height="50px" />
                      )}
                    </TableCell>
                    <TableCell width={'20%'}>
                      <Link style={{ textDecoration: 'none', color: 'black' }} to={`productdetails/${item._id}`}>
                        {item.title}
                      </Link>
                    </TableCell>
                    <TableCell>{item?.seller[0]?.price}</TableCell>
                    <TableCell>{item?.seller[0]?.qty}</TableCell>
                    <TableCell>{item.categoryId?.categoryName}</TableCell>

                    <TableCell>{item.brandId?.brandName}</TableCell>
                    <TableCell>
                      <Link href="products/productdetails">
                        <Icon style={{ color: 'black' }} height="20px" icon="carbon:view" />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </div>
  );
}
