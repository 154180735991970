import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as React from 'react';
import { CKEditor } from 'ckeditor4-react';
import { useDropzone } from 'react-dropzone';
// @mui
import slugify from 'slugify';
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Autocomplete,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Pagination,
  FormControl,
  Select,
  MenuItem,
  TextField,
  TableContainer,
  TablePagination,
  CardContent,
  Link,
  Grid,
  InputLabel,
} from '@mui/material';
// import { useMutation } from 'react-query';

import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq, postReq, patchReq } from '../../data/ApiReq';
import ApiUrl from '../../data/ApiUrl';
import productTags from '../../data/productTags';

const fileTypes = ['JPG', 'PNG'];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicProductInfo = ({
  handleBasicDetails,
  autoComHndl,
  editorHandle,
  handleSubmit,
  state,
  catData,
  brandData,
}) => (
  <Stack spacing={4}>
    <TextField
      fullWidth
      label="Product Title"
      shrink
      name="title"
      multiline
      variant="outlined"
      onChange={handleBasicDetails}
      defaultValue={state.title}
    />
    <Stack direction="row" alignItems="center" spacing={4}>
      <TextField
        label="Part Number"
        sx={{
          width: { md: 600 },
        }}
        variant="outlined"
        name="partNumber"
        onChange={handleBasicDetails}
        defaultValue={state.partNumber}
        shrink
      />
      <span>SKU : {slugify(state.title?.slice(0, 50) || 'product-no')}...</span>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={4}>
      <TextField
        label="Dimension"
        fullWidth
        variant="outlined"
        name="dimension"
        onChange={handleBasicDetails}
        defaultValue={state.dimension}
      />
      <TextField label="Weight" fullWidth variant="outlined" name="weight" onChange={handleBasicDetails} />
    </Stack>

    <Stack direction="row" alignItems="center" spacing={4}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={catData}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Categories" />}
        name="category"
        getOptionSelected={(option, value) => option._id === value._id}
        getOptionLabel={(option) => option.categoryName}
        onChange={(event, values) => autoComHndl('categoryId', values?._id)}
        defaultValue={catData[2]}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={brandData}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Brands" />}
        getOptionLabel={(option) => option.brandName}
        onChange={(event, values) => autoComHndl('brandId', values?._id)}
      />
    </Stack>

    <TextField
      fullWidth
      label="Product Short Description"
      multiline
      variant="outlined"
      minRows={3}
      onChange={handleBasicDetails}
      name="shortDescription"
    />
    <CKEditor
      type="inline"
      initData={<p>Product Description </p>}
      onChange={(event, editor) => editorHandle(event, event?.editor?.getData(), 'description', 'basicInfo')}
      name="description"
    />
  </Stack>
);

const ProductSpec = ({ handleBasicDetails, editorHandle }) => (
  <div>
    <Stack spacing={4}>
      <CKEditor
        type="inline"
        initData={<p>Product Specification </p>}
        name="specifications"
        onChange={(event, editor) => editorHandle(event, event?.editor?.getData(), 'specifications', 'techSpec')}
      />
      <TextField
        fullWidth
        label="Warrenty"
        multiline
        variant="outlined"
        onChange={handleBasicDetails}
        name="warrenty"
      />
    </Stack>
  </div>
);

const GTIN = (handleBasicDetails) => (
  <div>
    <Stack spacing={4}>
      <TextField label="UPC" name="upc" onChange={handleBasicDetails} variant="outlined" />
      <TextField label="EAN" name="ean" onChange={handleBasicDetails} variant="outlined" />
    </Stack>
  </div>
);

// eslint-disable-next-line arrow-body-style
const imageUpload = async (file) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('image', file);
    data.append('type', 'product');
    postReq({
      url: 'inventory/api/image/upload',
      data,
    })
      .then((response) => {
        console.log(response);
        if (!response.error) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ImgUploadSection = ({ handleUpload }) => {
  const [imgState, setImgState] = useState([]);

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles);

    const imgArr = [];
    const imgIdArr = [];

    acceptedFiles.forEach(async (file) => {
      const imgRes = await imageUpload(file);

      imgArr.push(imgRes);
      imgIdArr.push(imgRes._id);
    });
    setImgState(imgArr);
    handleUpload(imgIdArr);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    },
    maxFiles: 5,
  });
  let ImgPreview;

  return (
    // create a div with upload button and image preview
    <>
      <div {...getRootProps({ className: 'file-dropzone-wrap' })}>
        <input {...getInputProps()} />
        <p style={{ textAlign: 'center' }}>
          Drag 'n' drop image here, or click to select images <br />
          <i style={{ fontSize: '12px', color: '#848282' }}>
            {' '}
            * Upto 5 Images and Only .png, .jpg and .jpeg image will be accepted{' '}
          </i>
        </p>
      </div>

      <Stack direction={'row'} alignItems="center" gap={4} justifyContent="center" margin={10}>
        {imgState.map((file) => (
          <>
            <img src={`${ApiUrl.img_url}/products/${file.mdUrl}`} alt="img" className="img-prewview-upload" />
          </>
        ))}
      </Stack>
    </>
  );
};

const ProductTags = ({ autoComHndl }) => (
  <>
    <Stack spacing={4}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={productTags}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Multiple values" placeholder="Favorites" />
        )}
        onChange={(event, values) => autoComHndl('tags', values)}
      />
    </Stack>
  </>
);

const PriceAndQty = ({ handleBasicDetails, state, autoComHndl }) => (
  <div>
    <Stack spacing={4}>
      <Typography variant="h6">
        Sold & fulfilled by <span style={{ color: '#3f51b5' }}> Rookie Ninja LLC </span>
      </Typography>

      <Stack direction="row" alignItems="center" spacing={4}>
        <TextField
          label="Price"
          fullWidth
          variant="outlined"
          name="price"
          onChange={handleBasicDetails}
          // defaultValue={state.dimension}
        />
        <TextField label="Selling Price" fullWidth variant="outlined" name="sellPrice" onChange={handleBasicDetails} />
        <TextField label="Quantity" fullWidth variant="outlined" name="qty" onChange={handleBasicDetails} />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={4}>
        <TextField label="Warrenty" fullWidth variant="outlined" name="warrenty" onChange={handleBasicDetails} />
        <Autocomplete
          id="combo-box-demo"
          options={['New', 'Used', 'Refurbished']}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Condition" />}
          onChange={(event, values) => autoComHndl('conditions', values)}
        />
      </Stack>
    </Stack>
  </div>
);

export default function AddProducts() {
  const [value, setValue] = React.useState(0);
  const [basicDetails, setBasicDetails] = React.useState({});
  const [ckEditorText, setCkEditorText] = React.useState({});
  const [sellerDetails, setSellerDetails] = React.useState({});
  const [imgArr, setImgArr] = useState([]);

  const [brandData, setBrand] = useState([]);
  const [catData, setCategory] = useState([]);
  const [productId, setProductId] = useState(null);

  const getData = async () => {
    const res = await getReq({ url: 'inventory/api/brand' });
    if (!res.error) {
      setBrand(res.data);
    }

    const catRes = await getReq({ url: 'inventory/api/category' });
    if (!catRes.error) {
      setCategory(catRes.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCkEditor = (event, editor, name, section) => {
    console.log('event', event);
    setCkEditorText({ ...ckEditorText, [name]: editor });
  };

  const handleBasicDetails = (e) => {
    if (e.target.name === 'price' || e.target.name === 'sellPrice' || e.target.name === 'qty') {
      setSellerDetails({ ...sellerDetails, [e.target.name]: e.target.value });
      return;
    }
    setBasicDetails({ ...basicDetails, [e.target.name]: e.target.value });
  };

  const handleBasicAutoCom = (name, values) => {
    if (name === 'conditions') {
      setSellerDetails({ ...sellerDetails, conditions: values });
      return;
    }

    setBasicDetails({ ...basicDetails, [name]: values });
  };

  const handleMultiAutoCom = (name, values) => {
    console.log('values', values);
    console.log('name', name);
    const arr = [];
    values.forEach((element) => {
      arr.push(element._id);
    });

    setBasicDetails({ ...basicDetails, productTag: arr });
  };

  // const mutation = useMutation((data) => {
  //   postQuery('product', data);
  // });

  const handleSubmit = async () => {
    const data = { ...basicDetails, ...ckEditorText, image: imgArr, seller: [sellerDetails] };
    // console.log('BasickInfo', data);
    // mutation.mutate(data);
    let response;
    if (productId) {
      response = await patchReq({
        url: 'inventory/api/product',
        data: { ...data, productId },
      });

      if (!response.error) {
        setValue(value + 1);
      }
    } else {
      response = await postReq({
        url: 'inventory/api/product',
        data,
      });

      if (!response.error) {
        setProductId(response.data._id);
        setValue(value + 1);
      }
    }

    console.log(response);
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" mt={3} gutterBottom>
          Add Products
        </Typography>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Basic Product Info" {...a11yProps(0)} />
            <Tab label="Technical Details " {...a11yProps(1)} />
            <Tab label="GTIN" {...a11yProps(2)} />
            <Tab label="Product Images" {...a11yProps(3)} />
            <Tab label="Tags" {...a11yProps(4)} />
            <Tab label="Stock & Price" {...a11yProps(5)} />
            {/* <Tab label="Confirm" {...a11yProps(6)} />vasfhtwert */}
            {/* <Tab label="Keywords & filters" {...a11yProps(3)} /> */}
            {/* <Tab label="Product Datasheet" {...a11yProps(4)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <BasicProductInfo
            handleBasicDetails={handleBasicDetails}
            autoComHndl={handleBasicAutoCom}
            editorHandle={handleCkEditor}
            handleSubmit={handleSubmit}
            state={basicDetails}
            brandData={brandData}
            catData={catData}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ProductSpec handleBasicDetails={handleBasicDetails} editorHandle={handleCkEditor} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GTIN handleBasicDetails={handleBasicDetails} />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <ImgUploadSection handleUpload={setImgArr} />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <ProductTags autoComHndl={handleMultiAutoCom} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <PriceAndQty handleBasicDetails={handleBasicDetails} autoComHndl={handleBasicAutoCom} />
        </TabPanel>
        {/* <TabPanel value={value} index={6}>
          <h1>Confirm</h1>
        </TabPanel> */}
        <Stack direction={'row'} alignItems="center" justifyContent="center">
          <Button variant="contained" color="primary" sx={{ mr: 1 }} size="large" onClick={handleSubmit}>
            Save & Continue
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}
