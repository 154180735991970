const API_URL = {
  base_url: 'http://localhost:80',
  // base_url: 'https://b83c-83-110-222-214.in.ngrok.io/api',

  // base_url: 'https://api.mitykart.com/api',

  img_url: 'https://rn-ecommerce.s3.ap-south-1.amazonaws.com',

  COOKIETOKEN: 'JWT_TOKEN_213',
};

export default API_URL;
